.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  --color-background: #1d2125ff;
  --color-foreground: #f5f5f5;
  --header-background: #1d2125ff;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.main-content {
  flex: 1;
  background-color: var(--color-background);
  color: var(--color-foreground);
}

.modal-content {
  background-color: 'blue';
  color: var(--color-foreground);
  width: 100%;
}

.footer {
  background-color: var(--header-background);
  padding: 5px;
  color: var(--color-foreground);
}

.normal-text {
  background-color: transparent;
  color: var(--color-foreground);
}

.debug-text {
  background-color: green;
  color: var(--color-foreground);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar {
  /* list-style-type: none; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--header-background);
  color: var(--color-foreground);
  padding: 5px;
  border: white, solid, 1px;
}

.logo {
  display: flex;
  height: 50px;
  width: 126px;
  margin-left: 20px;
  margin-right: 8px;
}

.navbar-title {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 30px;
  margin-left: 20px;
}

.navbar-list {
  list-style-type: none;
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.navbar-item {
  margin: 0 10px;
}

.navbar-item a {
  text-decoration: none;
  color: whitesmoke;
  font-weight: bold;
}

.navbar-item a:hover {
  color: orange;
}

.iframe-hidden {
  width: 100%;
  height: 100svh;
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background-color: violet;
}

.iframe-hidden-modal {
  width: 90%;
  height: 90svh;
  border: none;
  position: absolute;
  top: 5%;
  left: 5%;
  z-index: -1;
  background-color: violet;
}

.iframe-fullscreen {
  width: 100%;
  height: 100svh;
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  background-color: violet;
}

.RulePartsTab {
  color: whitesmoke !important; /* Using !important to override Material-UI default styles */
  font-weight: 400 !important;
  font-size: 0.9em !important;
}
.myCustomSelect.myCustomSelect {
  text-align: left;
  background-color: #1d2125ff;
  color: whitesmoke;
}

.myCustomSelect.myCustomSelect .MuiOutlinedInput-notchedOutline {
  border-color: whitesmoke;
}

.myCustomSelect.myCustomSelect:hover .MuiOutlinedInput-notchedOutline {
  border-color: whitesmoke;
}

.myCustomSelect.myCustomSelect.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #1286a6;
}

.myCustomSelect.myCustomSelect .MuiSelect-icon {
  color: whitesmoke;
}

.myCustomSelectMenu.myCustomSelectMenu {
  background-color: lightgrey;
  color: black;
}
.customInputLabel.customInputLabel {
  color: whitesmoke;
}

.customOutlinedInput .MuiOutlinedInput-notchedOutline:focus {
  border-color: transparent;
}
.large-quote {
  font-size: 24px;
  line-height: 1;
  margin-left: 5px;
  margin-right: 5px;
}

.customAccordion.customAccordion {
  border: 1px solid #2c3238;
  box-shadow: none;
  background-color: #1d2125ff;
  color: whitesmoke;
  margin-bottom: 8px;
}

.customAccordionSummary.customAccordionSummary {
  background-color: #1d2125ff;
  border-bottom: 1px solid #2c3238;
  margin-bottom: -1px;
}

.customAccordionDetails.customAccordionDetails {
  padding: 0;
  background-color: #161a1dff;
}

.customIcon.customIcon {
  color: whitesmoke;
}
.customIcon.customIcon:hover {
  color: #1286a6bc;
}
.customIcon.customIcon:focus {
  color: #1286a6bc;
}
.ruleItem.ruleItem {
  margin: 0;
  padding: 8px 16px 8px 16px;
}
.ruleItem.ruleItem:hover {
  background-color: #1286a6af;
}
.ruleItem.ruleItem:focus {
  background-color: #1286a6af;
}
.ruleSection.ruleSection {
  margin-top: 2;
  background-color: #1d2125ff;
}
.taskItem.taskItem {
  border: 'solid #2c3238 1px';
}
.taskItem.taskItem:hover {
  background-color: #161a1dff;
}
.taskItem.taskItem:focus {
  background-color: #161a1dff;
}
.input-wrapper {
  position: relative;
  width: 100%;
}

.input-wrapper .MuiTextField-root {
  width: 100%;
}

.placeholder {
  font-family: monospace;
  height: 100%;
  pointer-events: none;
  color: #888;
  white-space: normal;
  transition: 0.2s ease all;
  text-align: left;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  background-color: whitesmoke;
  padding-top: 12px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 12px;
  z-index: 2;
}

.inputOverlay {
  font-family: monospace;
  background-color: transparent;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border: none;
  text-wrap: wrap;
  text-align: left;
  padding-top: 12px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 12px;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  font-weight: 400;
  font-size: 1rem;
  pointer-events: none;
  white-space: pre-wrap;
  z-index: 3;
}

/* .customTextField.customTextField {
  background-color: transparent;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border: none;
} */
.customTextField {
  font-family: monospace;
  background-color: transparent;
  color: rgb(203, 203, 203);
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border: none;
  text-wrap: wrap;
  text-align: left;
  padding-top: 12px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 12px;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  font-weight: 400;
  font-size: 1rem;
  white-space: pre-wrap;
  z-index: 1;
}
.customTextField:focus {
  outline: 0px;
}

.customToggleButton.customToggleButton {
  background-color: #1d2125ff;
  color: whitesmoke;
  border-color: #2c3238;
}

.customToggleButton.customToggleButton:hover {
  background-color: #8b8c8d4e;
}

.customToggleButton.customToggleButton.Mui-selected {
  background-color: #1286a665;
  color: #1bc8f8bc;
  border-color: #1286a6bc;
}
.customToggleButton.customToggleButton.Mui-selected:hover {
  background-color: #1286a6bc;
  color: #1bc8f8bc;
  border-color: #1286a6bc;
}
.customToggleButton.customToggleButton:not(.Mui-selected) {
  background-color: #1d2125ff;
  color: whitesmoke;
  border-color: #2c3238;
}
.customTextField::after {
  content: '';
  position: absolute;

  top: 0;
  left: 0;
  width: 2px;
  height: 1em;
  background: black;
}

@keyframes ripple {
  0% {
    text-shadow: 0 0 0px rgba(0, 255, 0, 0);
  }
  50% {
    text-shadow: 0 0 15px rgba(1, 188, 1, 0.5);
    font-size: 1.1em;
  }
  100% {
    text-shadow: 0 0 0px rgba(0, 255, 0, 0);
  }
}

.correct {
  /* display: inline-block; */
  animation: ripple 1s ease-in-out;
}
@keyframes roll {
  from {
    transform: rotateX(0deg);
  }
  to {
    transform: rotateX(360deg);
  }
}
.scoreNumber {
  display: inline-block;
  perspective: 300px;
}

.scoreNumber.animate {
  animation: roll 0.5s linear;
  transform-style: preserve-3d;
}

.bottom-toolbar {
  width: 100%;
  background-color: #363636;
  color: var(--color-foreground);
  padding: 5px;
}
.top-toolbar {
  width: 100%;
  display: flex;
  justify-content: right;
  align-items: right;
  background-color: #dfdfdf;
  padding: 0px;
}
.recite-section {
  border: #afafafbc solid 1px;
  display: flex;
  flex-direction: column;
  margin-bottom: 2em;
}

.flash {
  animation: flash 3s infinite;
}

@keyframes flash {
  0%,
  50%,
  100% {
    color: red;
  }
  25%,
  75% {
    color: white;
  }
}

.colorPrimary {
  background-color: #b2dfdb;
}

.barColorPrimary {
  background-color: #00695c;
}

@keyframes flashGreen {
  0% {
    background-color: #00695c;
  }
  50%,
  100% {
    background-color: green;
  }
}

.flashGreen {
  animation: flashGreen 1s ease-in-out 5;
}
.pulse {
  animation: pulsate 1s ease-in-out infinite;
}
@keyframes pulsate {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes moveAndFade {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-100px);
  }
}

.glow-effect {
  animation: glow 1s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    box-shadow: 0 0 10px #9c27b0;
  }
  to {
    box-shadow: 0 0 20px #9c27b0;
  }
}
@keyframes flash {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
}

.flash {
  animation: flash 0.5s linear infinite;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.fade-in-image {
  animation: fadeIn 2s ease-out;
}

.icon-container {
  position: relative;
  display: inline-block; /* Adjust as needed */
}

.icon-reflection::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30px; /* Adjust based on your needs for the reflection length */
  opacity: 0.5; /* Adjust opacity for better visibility */
  transform: scaleY(-1) translate(0, 100%);
  filter: blur(5px); /* Increase blur for a softer reflection */
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.2),
    transparent 70%
  );
}

.spinStar {
  animation: spinStar 3s infinite linear;
}
@keyframes spinStar {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}
.score-animation {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: scoreMoveAndFade 3s ease forwards;
  background-color: whitesmoke; /* Set the background color to whitesmoke */
  border-radius: 50%; /* Make the shape a circle */
  width: 30px; /* Set a fixed width */
  height: 30px; /* Set a fixed height to maintain aspect ratio */
  display: flex; /* Use flexbox to center the content */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  color: green;
  font-weight: bold; /* Optional: Make the text bold */
  font-size: 10px;
}

@keyframes scoreMoveAndFade {
  to {
    opacity: 0;
    top: 10%;
    left: 40%;
    transform: translate(0, 0);
  }
}
@keyframes appear {
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.found-icon {
  opacity: 0;
  transform: scale(0);
  animation: appear 0.3s forwards;
}
.dark-select {
  background-color: #333;
  color: #fff;
  border: 1px solid #555;
}

.dark-select option {
  background-color: #333;
  color: #fff;
}
